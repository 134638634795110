<template>
  <card>
    <div class="custom-container">
      <card class="shadow-none" id="resultados-evaluado">
        <template v-slot:headerTitle>
          <span class="card-title my-2 h4"><b>Coordenadas del conductor</b></span>
        </template>
        <!-- Botones -->
        <div class="col-12 col-md-6 col-lg-8">
          <CardTable :data="dataCards" />
        </div>

        <!-- Tabla de resultados -->
        <div class="table-responsive">
          <table id="datatable_resultadosEvaluacion" class="table table-bordered table-striped text-center">
            <thead>
              <tr>
                <th>Latitud Ubicación</th>
                <th>Longitud Ubicación</th>
                <th>Fecha y Hora</th>
                <th>Lugar de entrega</th>
                <th>Latitud Ubicación entrega</th>
                <th>Longitud Ubicación entrega</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in coordenadas" :key="index">
                <td>{{ item.latUbicacionActual }}</td>
                <td>{{ item.longUbicacionActual }}</td>
                <td>{{ item.fechaHoraActual }}</td>
                <td>{{ item.lugarEntrega }}</td>
                <td>{{ item.latUbicacionEntrega }}</td>
                <td>{{ item.longUbicacionEntrega }}</td>
                <td>{{ item.estadoCoordenadas }}</td>
                <td>
                  <!-- Botón para abrir Google Maps con las coordenadas del conductor y destino -->
                  <vs-button v-b-tooltip="{
                    title: `Ver en Google Maps`,
                    placement: 'top',
                    customClass: 'font_tooltip',
                  }" icon @click="abrirGoogleMaps(item)">
                    <i class="fas fa-map"></i> Ver en Google Maps
                  </vs-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </card>
    </div>
  </card>
</template>

<script>
import CardTable from "../../../components/cardTable/CardTable.vue";

export default {
  name: "DetallePuntoEntrega",
  components: {
    CardTable,
  },
  data() {
    return {
      dataCards: [
        {
          title: "Número de coordenadas registradas hasta el momento",
          value: () => this.coordenadas.length,
          iconName: "map-marker-alt",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            this.dataTableInstance.search("").draw();
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
          },
        },
      ],
      coordenadas: [],
    };
  },
  async created() {
    const puntoEntregaId = this.$route.params.id;
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    const empresaId = this.usuario.empresa.id;

    if (puntoEntregaId && empresaId) {
      await this.obtenerCoordenadasConductor(puntoEntregaId);
    } else {
      this.error = "No se proporcionaron parámetros válidos.";
    }
  },
  methods: {
    async obtenerCoordenadasConductor(puntoEntregaId) {
      try {
        const response = await this.$store.dispatch("hl_get", {
          path: `EntregasApp/GetCoordenadasConductorConPlantilla/${puntoEntregaId}`,
        });

        if (response.success) {
          this.coordenadas = response.data.map((item) => ({
            ...item,
            fechaHoraActual: this.formatearFecha(item.fechaHoraActual),
          }));
          console.log("Estos son los datos recibidos", this.coordenadas); // Verifica que las coordenadas se están asignando correctamente.
          this.inicializarDataTable();
        } else {
          this.error = response.message || "No se pudieron obtener las coordenadas del conductor.";
        }
      } catch (error) {
        console.error("Error al obtener las coordenadas del conductor:", error);
        this.error = "Ocurrió un error al obtener las coordenadas del conductor.";
      }
    },

    formatearFecha(fechaISO) {
      const date = new Date(fechaISO);
      const opciones = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true, // Esto asegura que se use el formato AM/PM
      };
      const fechaFormateada = new Intl.DateTimeFormat('es-ES', opciones).format(date);
      return fechaFormateada;
    },

    inicializarDataTable() {
      this.$nextTick(() => {
        if (window.$.fn.DataTable.isDataTable("#datatable_resultadosEvaluacion")) {
          window.$("#datatable_resultadosEvaluacion").DataTable().destroy();
        }
        window.$("#datatable_resultadosEvaluacion").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
          pageLength: 5,
        });
      });
    },

    actualizarDatos() {
      this.obtenerCoordenadasConductor(this.$route.params.id);
    },

    // Método para abrir Google Maps con las coordenadas del conductor y destino
    abrirGoogleMaps(item) {
      const latitudActual = item.latUbicacionActual;
      const longitudActual = item.longUbicacionActual;
      const latitudDestino = item.latUbicacionEntrega;
      const longitudDestino = item.longUbicacionEntrega;

      // Verificar las coordenadas que se están pasando
      console.log("Coordenadas origen:", latitudActual, longitudActual);
      console.log("Coordenadas destino:", latitudDestino, longitudDestino);

      // Crear la URL de Google Maps con las coordenadas de origen y destino
      const url = `https://www.google.com/maps/dir/?api=1&origin=${latitudActual},${longitudActual}&destination=${latitudDestino},${longitudDestino}&travelmode=driving`;

      // Abrir la URL en una nueva pestaña
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
/* Estilos específicos si son necesarios */
</style>
